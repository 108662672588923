import { createGlobalStyle } from "styled-components"
import knightsQuestRegular from "./data/fonts/knights-quest.regular.ttf"

const GlobalStyles = createGlobalStyle`
@font-face {
        font-family: 'KnightsQuest';
        src: url(${knightsQuestRegular});
        font-weight: 300;
        font-style: normal;
    }
  body {
    position: relative;
    margin: 0px;
    padding: 0px;
    font-family: 'KnightsQuest', sans-serif;
    font-size: 16px;
  }
  a {
    color: white;
    text-decoration: none;
  }

  h1 {
    text-align: center;
    margin: 0;
  }

  button{
     font-family: 'Teko', sans-serif;
  }
  section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  }
  address {
    font-style: normal;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: 'Teko', sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: 'Teko', sans-serif;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: 'Teko', sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: 'Teko', sans-serif;
}
`

export default GlobalStyles

import styled from "styled-components"
import { IBackground } from "./types"
import {
  m15px,
  p15px,
  p2px,
  block,
  pointer,
  trans03s,
  relative,
  absolute,
  lightLinearBackground,
  darkLinearBackground,
  steelPurpleBackground,
  borderRadius5px,
} from "../../global"

export const ButtonBorder = styled.div`
  ${block}
  ${m15px}
  ${steelPurpleBackground}
  ${p2px}
  ${borderRadius5px}
`

export const Background = styled.div<IBackground>`
  ${relative}
  ${props => props.type === "lightPurple" && lightLinearBackground}
  ${props => props.type === "darkPurple" && darkLinearBackground}
  color: ${props => props.type === "lightPurple" && "black"};
  color: ${props => props.type === "darkPurple" && "white"};
  ${p15px}
  ${borderRadius5px}
  ${trans03s}
  overflow: hidden;
  &:after{
      ${absolute}
      ${trans03s}
      left: -90%;
      content: "";
      width: 80%;
      height:50px;
      top: 0;
      background-color: rgba(0, 0, 0, 0.1);
      transform: skewX(-20deg);
    }
  &:hover{
    ${pointer}
    ${trans03s}
    &:after{
      left: 30%;
    }
  }
`

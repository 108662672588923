import React from "react"
import {
  HeaderSection,
  HeaderWrapper,
  LogoWrapper,
  NavWrapper,
  SocialIcon,
  NavLink,
  Title,
  DividerSpan,
} from "./styles"
import IHeader from "./types"

import twitterSquare from "@iconify-icons/fa-brands/twitter-square"
import bxlPatreon from "@iconify-icons/bx/bxl-patreon"
import facebookIcon from "@iconify-icons/entypo-social/facebook"
import instagramSquare from "@iconify-icons/fa-brands/instagram-square"

const Header = ({}: IHeader) => {
  return (
    <header>
      <HeaderSection>
        <HeaderWrapper>
          <LogoWrapper>
            <Title>
              Call{" "}
              <DividerSpan>
                <br />
                TO
                <br />
              </DividerSpan>{" "}
              Heroes
            </Title>
          </LogoWrapper>
          <NavWrapper>
            <div>
              <NavLink to="/game">Game</NavLink>
              <NavLink to="/news">News</NavLink>
              <NavLink to="/channel log">Channel Log</NavLink>
              <NavLink to="/game">Game</NavLink>
            </div>
            <div>
              <SocialIcon icon={facebookIcon} />
              <SocialIcon icon={instagramSquare} />
              <SocialIcon icon={twitterSquare} />
              <SocialIcon icon={bxlPatreon} />
            </div>
          </NavWrapper>
        </HeaderWrapper>
      </HeaderSection>
    </header>
  )
}

export default Header

import styled from "styled-components"
import { Container, P as _P, H2Title as _H2Title } from "../../global"
import { Section } from "../../global"

export const AboutSection = styled(Section)`
  overflow: hidden;
  background: linear-gradient(180deg, #a0a0a0 0%, #ededed 100%);
`

export const AboutContainer = styled(Container)`
  margin-top: 70px;
  margin-bottom: 70px;
`

export const H2Title = styled(_H2Title)`
  margin-bottom: 90px;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export const Image = styled.div`
  position: absolute;
  height: 100%;
  width: 50vw;
  background-color: seagreen;
`

export const P = styled(_P)`
  margin-top: 60px;
  margin-bottom: 60px;
`

export const PM15px = styled(P)`
  margin-left: 15px;
  margin-right: 15px;
`

import styled, { css } from "styled-components"

export const container = css`
  width: 1200px;
`

export const row = css`
  flex-direction: row;
`
export const flexRow = css`
  display: flex;
  ${row}
`
export const col = css`
  flex-direction: column;
`
export const m15px = css`
  margin: 15px;
`
export const block = css`
  display: block;
`
export const p15px = css`
  padding: 15px;
`
export const p2px = css`
  padding: 2px;
`
export const lightLinearBackground = css`
  background: linear-gradient(180deg, #a277bc 0%, #bba2d4 100%);
`
export const darkLinearBackground = css`
  background: linear-gradient(180deg, #34263d 0%, #745e8a 100%);
`
export const steelPurpleBackground = css`
  background: linear-gradient(180deg, #ffffff 0%, #6054a8 100%);
`
export const font13rem = css`
  font-size: 1.3rem;
`
export const font15rem = css`
  font-size: 1.5rem;
`
export const font20rem = css`
  font-size: 2rem;
`
export const textCenter = css`
  text-align: center;
`
export const bold = css`
  font-weight: bold;
`
export const borderRadius5px = css`
  border-radius: 5px;
`
export const trans03s = css`
  transition: 0.3s;
`

export const pointer = css`
  cursor: pointer;
`

export const relative = css`
  position: relative;
`
export const absolute = css`
  position: absolute;
`

export const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Container = styled.div`
  width: 1200px;
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const H2Title = styled.h2`
  ${font20rem}
  ${bold}
  ${textCenter}
`

export const P = styled.p`
  ${font13rem}
`

export const DoubleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 25px;
  grid-template-areas:
    ". ."
    ". .";
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas:
      ". "
      ". ";
  }
`

import styled, { keyframes } from "styled-components"
import { Section, Container, FlexCenter } from "../../global"
import { Icon } from "@iconify/react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const menuIdle_anim = keyframes`
    0% {
        transform: translateY(0px) rotateY(0deg)
    }
    50% {
        transform: translateY(5px) rotateY(5deg)
    }
    100% {
        transform: translateY(0px) rotateY(0deg)
    }
`

export const HeaderSection = styled(Section)`
  position: fixed;
  z-index: 1000;
  height: 120px;
`

export const HeaderWrapper = styled(Container)`
  animation: ${menuIdle_anim} 8s ease-in-out infinite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0 15px 0 15px;
`

export const LogoWrapper = styled(FlexCenter)`
  width: 240px;
  height: 100px;
  background-color: #c4c4c4;
`
export const Title = styled.h1`
  margin: 0px 15px 0px 15px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 15px;
`
export const DividerSpan = styled.span`
  font-size: 1.1rem;
`

export const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 240px);
  height: 64px;
  background-color: #c4c4c4;
  @media (max-width: 920px) {
    display: none;
  }
`
export const SocialIcon = styled(Icon)`
  width: 46px;
  height: 46px;
  margin: 2px;
  color: black;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }
`
export const NavLink = styled(AnchorLink)`
  font-size: 24px;
  padding: 10px 20px 10px 20px;
  color: black;
`

export const MenuWrapper = styled.div``
export const SocialWrapper = styled.div``

import React from "react"
import ILanding from "./types"
import {
  LandingSection,
  LandingTitle,
  LandingDescription,
  LandingContainer,
  ButtonsContainer,
} from "./styles"

import Button from "../../ui/button"

const Landing = ({}: ILanding) => {
  return (
    <LandingSection>
      <LandingContainer>
        <LandingTitle>YOUR JOURNEY STARTS HERE</LandingTitle>
        <LandingDescription>
          Aliquam erat volutpat. Aliquam convallis vestibulum eros vitae
          placerat. Sed in blandit nulla. Cras porttitor risus tortor, quis
          interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
          faucibus.{" "}
        </LandingDescription>
        <ButtonsContainer>
          <Button type="lightPurple">SUBSCRIBE NOW</Button>
          <Button type="darkPurple">LEARN MORE</Button>
        </ButtonsContainer>
      </LandingContainer>
    </LandingSection>
  )
}

export default Landing

import React from "react"
import IAbout from "./types"
import {
  AboutContainer,
  ImageWrapper,
  P,
  H2Title,
  Image,
  AboutSection,
  PM15px,
} from "./styles"
import { Section, DoubleGrid } from "../../global"

const About = ({}: IAbout) => {
  return (
    <>
      <AboutSection>
        <AboutContainer>
          <H2Title>Mauris nibh lorem, sagittis ac malesuada congue</H2Title>
          <DoubleGrid>
            <P style={{ textAlign: "right" }}>
              Aliquam erat volutpat. Aliquam convallis vestibulum eros vitae
              placerat. Sed in blandit nulla. Cras porttitor risus tortor, quis
              interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
              faucibus. Aliquam erat volutpat. Aliquam convallis vestibulum eros
              vitae placerat. Sed in blandit nulla. Cras porttitor risus tortor,
              quis interdum quam dignissim ac. Sed commodo tincidunt mi
              facilisis faucibus. Aliquam erat volutpat. Aliquam convallis
              vestibulum eros vitae placerat. Sed in blandit nulla. Cras
              porttitor risus tortor, quis interdum quam dignissim ac. Sed
              commodo tincidunt mi facilisis faucibus.{" "}
            </P>

            <ImageWrapper>
              <Image style={{ left: "0px" }} />
            </ImageWrapper>

            <ImageWrapper>
              <Image style={{ right: "0px" }} />
            </ImageWrapper>
            <P style={{ textAlign: "left" }}>
              Aliquam erat volutpat. Aliquam convallis vestibulum eros vitae
              placerat. Sed in blandit nulla. Cras porttitor risus tortor, quis
              interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
              faucibus. Aliquam erat volutpat. Aliquam convallis vestibulum eros
              vitae placerat. Sed in blandit nulla. Cras porttitor risus tortor,
              quis interdum quam dignissim ac. Sed commodo tincidunt mi
              facilisis faucibus. Aliquam erat volutpat. Aliquam convallis
              vestibulum eros vitae placerat. Sed in blandit nulla. Cras
              porttitor risus tortor, quis interdum quam dignissim ac. Sed
              commodo tincidunt mi facilisis faucibus.{" "}
            </P>
          </DoubleGrid>
        </AboutContainer>
      </AboutSection>
      <AboutSection>
        <AboutContainer>
          <PM15px style={{ textAlign: "center" }}>
            Aliquam erat volutpat. Aliquam convallis vestibulum eros vitae
            placerat. Sed in blandit nulla. Cras porttitor risus tortor, quis
            interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
            faucibus. Aliquam erat volutpat. Aliquam convallis vestibulum eros
            vitae placerat. Sed in blandit nulla. Cras porttitor risus tortor,
            quis interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
            faucibus. Aliquam erat volutpat. Aliquam convallis vestibulum eros
            vitae placerat. Sed in blandit nulla. Cras porttitor risus tortor,
            quis interdum quam dignissim ac. Sed commodo tincidunt mi facilisis
            faucibus.{" "}
          </PM15px>
        </AboutContainer>
      </AboutSection>
    </>
  )
}

export default About

import React, { PropsWithChildren } from "react"
import ILayout from "./types"
import GlobalStyles from "../../../globalStyles"
import Header from "../header"
const Layout = ({ children }: PropsWithChildren<ILayout>) => {
  return (
    <div>
      <GlobalStyles />
      <Header />
      <div>{children}</div>
      <div>FOOTER</div>
    </div>
  )
}

export default Layout

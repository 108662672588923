import styled from "styled-components"
import {
  Section,
  container,
  flexCenter,
  col,
  m15px,
  flexRow,
} from "../../global"

export const LandingSection = styled(Section)`
  background: linear-gradient(180deg, #a0a0a0 0%, #ededed 100%);
  height: 100vh;
`
export const LandingTitle = styled.h1`
  font-size: 5rem;
  font-weight: 100;
`

export const LandingDescription = styled.p`
  font-size: 20px;
  text-align: center;
`

export const LandingContainer = styled.div`
  ${container}
  ${flexCenter}
  ${col}
  ${m15px}
`

export const ButtonsContainer = styled.div`
  ${flexRow}
`

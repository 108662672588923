import React, { PropsWithChildren } from "react"
import { ButtonBorder, Background } from "./styles"
import IButton from "./types"

const Button = ({ children, type }: PropsWithChildren<IButton>) => {
  return (
    <ButtonBorder>
      <Background type={type}>{children}</Background>
    </ButtonBorder>
  )
}

export default Button

import React from "react"
import Layout from "../components/ui/layout"
import Landing from "../components/sections/landing"
import About from "../components/sections/about"

const IndexPage = () => (
  <Layout>
    <Landing />
    <About />
  </Layout>
)

export default IndexPage
